<template>
    <div class="SkuPurchase" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="关键字" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="类目">
                            <el-select placeholder="类目" v-model="form.categoryCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option-group v-for="group in meta.categories" :label="group.name" :key="group.id">
                                    <el-option
                                        v-for="category in group.sub"
                                        :label="category.name"
                                        :value="category.code"
                                        :key="category.code"
                                    />
                                </el-option-group>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="品牌">
                            <el-select placeholder="品牌" v-model="form.brandCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option v-for="bd in meta.brands" :label="bd.name" :value="bd.code" :key="bd.code" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                v-if="hasPrivilege('menu.goods.skuPurchase.open')"
                @click="searchHandleQuery"
                size="small"
                >查询
            </el-button>
            <el-button
                type="primary"
                v-if="hasPrivilege('menu.goods.skuPurchase.export')"
                @click="handleExport"
                size="small"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                ref="table"
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="sku.name" v-if="showColumn('sku.name')" label="商品名称" width="180" />
                <el-table-column prop="sku.bars" v-if="showColumn('sku.bars')" key="sku.bars" label="条码" width="180">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.bars.map((d) => d).join(' / ') }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="sku.specs"
                    v-if="showColumn('sku.specs')"
                    key="sku.specs"
                    label="规格"
                    width="120"
                />
                <el-table-column
                    prop="sku.categoryCode"
                    v-if="showColumn('sku.categoryCode')"
                    key="sku.categoryCode"
                    label="类目"
                    width="180"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.category }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="sku.unit" v-if="showColumn('sku.unit')" label="单位" width="180" />
                <el-table-column
                    prop="sku.brandCode"
                    v-if="showColumn('sku.brandCode')"
                    key="sku.brandCode"
                    label="品牌"
                    width="180"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.brand }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否允许单方采购"
                    key="skuSinglePurchaseFlag"
                    v-if="showColumn('type1') || showColumn('type2') || showColumn('type3')"
                >
                    <el-table-column
                        prop="type1"
                        key="type1"
                        label="电商总部"
                        width="80"
                        v-if="hasPrivilege('biz.skuPurchase.head.operate') && showColumn('type1')"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.type1">
                                <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="type2"
                        key="type2"
                        label="配送中心"
                        v-if="hasPrivilege('biz.skuPurchase.repo.operate') && showColumn('type2')"
                        width="80"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.type2">
                                <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="type3"
                        key="type3"
                        label="门店"
                        v-if="hasPrivilege('biz.skuPurchase.store.operate') && showColumn('type3')"
                        width="80"
                    >
                        <template slot-scope="scope">
                            <span v-if="scope.row.type3">
                                <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                            </span>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="150"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            v-if="hasPrivilege('menu.goods.skuPurchase.open')"
                            @click="rowDetail(scope.row)"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            v-if="hasPrivilege('menu.goods.skuPurchase.edit')"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            >编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../../../js/UrlUtils';
import Util from '../../../js/Util';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'SkuPurchase',
    components: { CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],

    data() {
        return {
            form: {
                search: '',
                categoryCode: '',
                brandCode: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/goods/sku/single/purchase/queryExtend',
            },
            meta: {
                brands: [],
                categories: [],
            },
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.GoodsBrands(_this, (data) => {
            _this.meta.brands = data;
        });
        UrlUtils.GroupedGoodsCategories(_this, (rst) => {
            _this.meta.categories = rst;
        });
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            UrlUtils.Export(this, '单方采购设置', '/goods/sku/single/purchase/exportExtend', this.form);
        },
        handleSizeChange(size) {
            this.form.page = 1;
            this.form.limit = size;
            this.handleQuery();
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.goods.SkuPurchase.detail', ['商品管理', '单方采购设置', '采购详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.goods.skuPurchase.edit', ['商品管理', '单方采购设置', '编辑采购信息'], {
                form: row,
                code: row.code,
            });
        },
        handleCurrentChange(page) {
            this.form.page = page;
            this.handleQuery();
        },
        handlePrevClick(page) {
            this.form.page = page;
        },
        handleNextClick(page) {
            this.form.page = page;
        },
    },
    filters: {
        enable(enable) {
            return enable ? '是' : '';
        },
    },
};
</script>

<style scoped>
.SkuPurchase .el-form-item {
    margin-bottom: 0;
}
</style>
